const validationStatusFilter = {
    namespaced: true,
    state: {
        data: ""
    },
    mutations: {
        SET_DATA(state, status) {
            state.data = status;
        }
    },
    actions: {
        setData(context, value) {
            context.commit("SET_DATA", value);
        }
    },
    getters: {
        data: state => {
            return state.data;
        },
        toParams: state => {
            return [{ column: "status", value: state.data }];
        }
    }
};

export default validationStatusFilter;
